import React from 'react';
import Styles from './highlight-h1.module.scss';

export default ({ color, row1, row2, row3 }) => (
    <div className="row">
        <div className="col-12 col-sm-4 offset-sm-7 col-md-3 offset-md-8 col-lg-2 offset-lg-9 offset-xl-10" style={{ marginBottom: '-2px' }}>
            <h1 className={`text-uppercase mb-0 ${'color-' + color || 'white'}`} style={{ lineHeight: 0.8 }}>
                {row1 && row1.length > 0 ? (
                    <span className={`font-weight-normal ${Styles.span} ${row2.length > (row1.length * 2) ? 'ml-5' : ''}`}>{row1}</span>
                ) : ''}
                {row2 && row2.length > 0 ? (
                    <React.Fragment>
                        <br></br>
                        <span className={`${Styles.span} ${row2.length < 5 ? Styles.size4Rem : ''}`}>{row2}</span>
                    </React.Fragment>
                ) : ''}
                {row3 && row3.length > 0 ? (
                    <React.Fragment>
                        <br></br>
                        <span className={`${Styles.span} ${row3.length < 5 ? Styles.size4Rem : ''}`}>{row3}</span>
                    </React.Fragment>
                ) : ''}
            </h1>
        </div>
    </div>
);
