import { Link } from 'gatsby';
import React from 'react';
import { isExternalLink, isInternalAnchorLink, isMailtoOrTelLink } from '../../../utils/links';
import Styles from './button.module.scss';

export default props => {
    if (isMailtoOrTelLink(props.link) || isInternalAnchorLink(props.link)) {
        return (
            <a className={`${Styles.ostrButton} unstyled d-inline-flex align-items-center ${props.color === 'green' ? Styles.green : ''} ${props.small ? Styles.small : ''}`}
                href={props.link}>
                {props.icon ? (<i className="material-icons">{props.icon}</i>) : ''}
                {props.text}
            </a>
        );
    }

    if (isExternalLink(props.link)) {
        return (
            <a className={`${Styles.ostrButton} unstyled d-inline-flex align-items-center ${props.color === 'green' ? Styles.green : ''} ${props.small ? Styles.small : ''}`}
                href={props.link} target="_blank" rel="noopener noreferrer">
                {props.icon ? (<i className="material-icons">{props.icon}</i>) : ''}
                {props.text}
            </a>
        );
    }
    
    return (
        <Link className={`${Styles.ostrButton} unstyled d-inline-flex align-items-center ${props.color === 'green' ? Styles.green : ''} ${props.small ? Styles.small : ''}`} to={props.link}>
            {props.icon ? (<i className="material-icons">{props.icon}</i>) : ''}
            {props.text}
        </Link>
    );
}
