export function getHeaderImageSlice(image: string) {
    return {
        primary: {
            image: {
                url: image
            },
            color: 'green',
            row_1: {
                text: 'Die'
            },
            row_2: {
                text: 'Nestbau'
            },
            row_3: {
                text: 'Experten'
            }
        }
    };
}

export function getHeadingAndTextSlice(heading: string, text: string) {
    return {
        primary: {
            heading: {
                text: heading
            },
            text: {
                html: text
            }
        }
    }
}
