import React from 'react';
import HighlightH1 from '../highlight-h1/highlight-h1';
import Styles from './image-header.module.scss';

export default props => (
    <header style={{ backgroundImage: `url(${props.slice.primary.image.url})` }} className={`mb-5 position-relative ${Styles.header}`}>
        <div className={Styles.overlay}></div>
        <div className="container">
            <HighlightH1
                color={props.slice.primary.color}
                row1={props.slice.primary.row_1.text}
                row2={props.slice.primary.row_2.text}
                row3={props.slice.primary.row_3.text}/>
        </div>
    </header>

);
