import React from 'react';

export default props => (
    <section className="container" id={props.slice.primary.id ? props.slice.primary.id.text : undefined}>
        <div className="row">
            <div className="col-12 col-md-10">
                <h1 className="text-uppercase color-grey" dangerouslySetInnerHTML={{ __html: props.slice.primary.heading.text }}></h1>
                <div dangerouslySetInnerHTML={{ __html: props.slice.primary.text.html }}></div>
            </div>
        </div>
    </section>
);
