import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
// @ts-ignore
import Styles from './navbar.module.scss';

const navbarQuery = graphql`
{
    navbar: prismicNavbar {
        data {
            links {
                link {
                    text
                }
                text {
                    text
                }
                fragment {
                    text
                }
            }
        }
    }
}
`;

export default () => {
    const data = useStaticQuery(navbarQuery);
    const [isNavbarOpen, setNavbarOpen] = useState(false);
    const toggleNavbar = () => setNavbarOpen(!isNavbarOpen);

    return (
        <React.Fragment>
            <nav className={`navbar navbar-expand-lg fixed-top d-flex flex-column p-0`}>
                <div className="w-100 bg-white py-2 py-lg-0">
                    <div className="container">
                        <div className="row justify-content-between w-100">
                            <Link className="navbar-brand pl-3 d-flex align-items-center" to={'/'}>
                                <img src="/images/oster_logo.png" className="brand-image" alt="Oster Dach + Holzbau GmbH"></img>
                            </Link>
                            <a className="unstyled color-green d-none d-lg-flex align-items-center font-montserrat pr-3" href="tel:+49653150040">
                                <i className={`material-icons mr-1 ${Styles.materialIconsPhone}`}>phone</i> 06531 5004 0
                            </a>
                            <button className="navbar-toggler border-0 outline-none" onClick={() => toggleNavbar()}>
                                <i className="material-icons fade-in">{isNavbarOpen ? 'close' : 'menu'}</i>
                            </button>
                        </div>
                        {isNavbarOpen ? (
                            <div className="row fade-in w-100">
                                <div className={`w-100 d-flex justify-content-center justify-content-lg-end ${Styles.fullScreenNavigation}`}>
                                    <ul className="navbar-nav text-center mt-2 mt-md-0">
                                        {
                                            data.navbar.data.links.map((link, index) => (
                                                <li key={index} className="nav-item">
                                                    <Link className="nav-link p-0 font-montserrat"
                                                        activeClassName="color-green"
                                                        to={link.link.text + (link.fragment.text ? '#' + link.fragment.text : '')}
                                                        onClick={() => setNavbarOpen(false)}>
                                                        {link.text.text}
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        ) : ''}
                    </div>
                    <div className="bg-grey">
                        <div className="container">
                            <div className="row d-none d-lg-block">
                                <div className="col">
                                    <ul className="navbar-nav text-center mt-2 mt-md-0">
                                        {
                                            data.navbar.data.links.map((link, index) => (
                                                <li key={index} className={`nav-item ${index === 0 ? 'pl-0' : ''}`}>
                                                    <Link className="nav-link p-0 color-white font-montserrat text-uppercase"
                                                        activeClassName="color-green"
                                                        to={link.link.text + (link.fragment.text ? '#' + link.fragment.text : '')}
                                                        onClick={() => setNavbarOpen(false)}>
                                                        {link.text.text}
                                                    </Link>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav >

        </React.Fragment >
    );
}
